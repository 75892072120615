import React, { useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { UsernameContext } from "../components/UsernameContext";
import logo from "../images/logo.png";
import "../styles/Login.css";

const config = require("../configure.json").API;

const Login = () => {
  const navigate = useNavigate();
  const { setUsername } = useContext(UsernameContext);
  const backendGoogleUrl = `${config.google}`;

  const handleLoginSuccess = (credentialResponse) => {
    console.log('Login Success, but need to refresh token');
    var token = credentialResponse.credential;
    var decoded = jwt_decode(token);
    const username = decoded?.given_name;
    setUsername(username);
    localStorage.setItem("username", username);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token })
    };
    fetch(backendGoogleUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);
            localStorage.setItem("stay_logged_in", data.stay_logged_in);
            navigate("/");
        });
  };

  const handleLoginError = () => {
    console.log("Login Failed");
  };

  return (
    <div className="login-container">
      <img className="login-logo" src={logo} alt="Rentalmatics logo" />
      <div className="login">
        <h3>
          <br />
          Customer Health
          <br />
          <span style={{ color: "#fcbe2e", fontSize: "110%" }}>Dashboard</span>
        </h3>

        <p className="google-terms" style={{ textAlign: "center" }}>
          Log in to view customer progress:
        </p>

        <div className="google-btn">
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginError}
            // ux_mode="redirect"
            auto_select
          />
        </div>

        <p className="google-terms">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            Privacy Policy
          </a>
          and
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            Terms of Service
          </a>
          apply.
        </p>
      </div>
    </div>
  );
};

export default Login;
