import download from "../images/download.png";
import authFetch from "../services/NetworkUtils"

const DeviceIssuesDownloadButton = ({excelUrl, prefix}) => {
  const handleDownload = () => {
    authFetch({ url: `${excelUrl}`,
                method: 'GET',
                responseType: 'blob', // important
              })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const filename = response.headers.get('Content-Disposition').split('filename=')[1].replace(/['"]+/g, '');
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  return <span id="big-down-btn">
      <button onClick={handleDownload} title={"Download"}>
        <img src={download} alt="download" />
      </button>
  </span>
};

export default DeviceIssuesDownloadButton;
