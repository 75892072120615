import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import "../styles/Calendar.css";

const CalendarButton = ({ onSelectDate, buttonLabel , defaultDate}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    // Close the calendar when clicking outside the component
    const handleOutsideClick = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

  }, []);

  const handleButtonClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    setShowCalendar(false); // Collapse the calendar when a date is selected
    onSelectDate(date);
  };

  return (
    <div className="calendar-button" ref={calendarRef}>
      <button onClick={handleButtonClick}>
        {buttonLabel || "Toggle Calendar"}
      </button>
      <div className={`calendar-container ${showCalendar ? "" : "collapsed"}`}>
        <Calendar
          onDateSelection={handleDateSelection}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          initialMonth={selectedDate || moment(defaultDate)} // Use moment(defaultDate) for the initial month
          onSelectDate={onSelectDate} // Pass onSelectDate to the Calendar component
        />
      </div>
    </div>
  );
};

const Calendar = ({
  onDateSelection,
  selectedDate,
  setSelectedDate,
  initialMonth,
  onSelectDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState(initialMonth);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    onDateSelection(date);
  };

  const goToPreviousMonth = () => {
    const previousMonth = currentMonth.clone().subtract(1, "month");
    setCurrentMonth(previousMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = currentMonth.clone().add(1, "month");
    setCurrentMonth(nextMonth);
  };

  const renderCalendar = () => {
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const startDayIndex = startOfMonth.day();
    const totalDaysInMonth = endOfMonth.date();
    const calendarDays = Array.from({ length: totalDaysInMonth }, (_, index) =>
      startOfMonth.clone().add(index, "day")
    );

    // Fill in empty slots at the beginning of the calendar if the month doesn't start on Monday
    for (let i = 0; i < startDayIndex; i++) {
      calendarDays.unshift(null);
    }

    // Fill in empty slots at the end of the calendar if the month doesn't end on Sunday
    while (calendarDays.length % 7 !== 0) {
      calendarDays.push(null);
    }

    return (
      <div className="calendar">
        <div className="calendar-header">
          {shouldRenderPreviousButton() && (
            <button onClick={goToPreviousMonth}>&lt;</button>
          )}
          <h3>{currentMonth.format("MMMM YYYY")}</h3>
          {shouldRenderNextButton() && (
            <button onClick={goToNextMonth}>&gt;</button>
          )}
        </div>
        
        <div className="calendar-days">
          {moment.weekdaysMin().map((day) => (
            <div key={day}>{day}</div>
          ))}
        </div>
        <div className="calendar-grid" style={{ height: "235px" }}>
          {calendarDays.map((date, index) => {
            if (!date) {
              return <div key={index} className="empty-day" />;
            }
            const isSelected = date.isSame(selectedDate, "day");
            const isToday = date.isSame(moment(), "day");
            const isAfterToday = date.isAfter(moment(), "day");
            const isBeforeData = date.isBefore(moment("2023-06-14"), "day");
            const isHighlighted = date.isSame(onSelectDate, "day");

            let className = isSelected
              ? "selected"
              : isToday
              ? "today"
              : isAfterToday
              ? "after-today"
              : isBeforeData
              ? "before-data"
              : "";

            if (isHighlighted) {
              className += " highlighted";
            }

            return (
              <button
                key={date.format("YYYY-MM-DD")}
                onClick={() => handleDateClick(date)}
                className={className}
                disabled={isAfterToday || isBeforeData}
              >
                {date.format("D")}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const shouldRenderPreviousButton = () => {
    return currentMonth.isSameOrAfter(moment("2000"), "year");
  };
  
  const shouldRenderNextButton = () => {
    return currentMonth.isSameOrBefore(moment("3000"), "year");
  };  

  return <div className="calendar-wrapper">{renderCalendar()}</div>;
};

export default CalendarButton;
