// Customer.js
import React, { useEffect, useState, useRef } from "react";
import { differenceInCalendarDays } from 'date-fns';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Select from 'react-select';
import Switch from "react-switch";
import moment from "moment";

import "../styles/WeeklyReports.css";
import WeeklyReportsDownloadButton from "../components/WeeklyReportsDownloadButton";
import authFetch from "../services/NetworkUtils"
import close_img from "../images/close.png";


const WeeklyReports = () => {
  const config = require("../configure.json").API;
  const [tenantList, setTenantList] = useState([]);
  const [hoverRange, setHoverRange] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [downloadButtonPressed, setDownloadButtonPressed] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [sendComplete, setSendComplete] = useState(false);
  const [switchCheckedState, setSwitchCheckedState] = useState(false);

  const downloadUrl = `${config.api_weekly_report}`;

  const handleButtonPress = () => {
    setDownloadButtonPressed(true);
    setDownloadComplete(false);
    setSendComplete(false);
  };
  const handleDownloadComplete = () => {
    setDownloadComplete(true);
    setDownloadButtonPressed(false);
  };
  const handleSendComplete = () => {
    setSendComplete(true);
    setDownloadButtonPressed(false);
  };

  function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
  }
  function getWeekRange(date) {
    return {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    };
  }

  const handleDayChange = date => {
    setSelectedDays(getWeekDays(getWeekRange(date).from))
    setDownloadButtonPressed(false);
    setDownloadComplete(false);
  };
  const handleDayEnter = date => {
    setHoverRange(getWeekRange(date))
  };
  const handleDayLeave = () => {
    setHoverRange(undefined)
  };
  const handleWeekClick = (weekNumber, days, e) => {
    setSelectedDays(days)
  };

  const daysAreSelected = selectedDays && selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  function isFutureDate(date: Date) {
    const sunday = moment().startOf('week');
    return differenceInCalendarDays(date, sunday) >= 0;
  }

  const selectInputRef = useRef();

  const onTenantClear = () => {
    if (selectInputRef.current.getValue().length !== 0) {
      selectInputRef.current.clearValue();
    }
  };

  const handleTenantChange = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedTenant(selectedOption.value);
    } else {
      setSelectedTenant(null);
    }
  };

  const handleSwitchChange = (checked) => {
    setSwitchCheckedState(checked);
  };

  useEffect(() => {
    const tenantListUrl = `${config.api_tenant_list}?include_trial=true`;
    Promise.all([
      authFetch(tenantListUrl).then((response) => response.data),
    ])
    .then(
      ([
        api_tenant_list,
      ]) => {
        var tList = api_tenant_list.map((val) => {
                          return { value: val.tenantId, label: val.clientName };
                        })
        setTenantList(tList);
      }
    )
    .catch((error) => {
      console.error(error);
    });
  }, [config.api_tenant_list]);

  return (
    <>
      <div className="fade-in">
        <div style={{ overflowX: "auto" }}>
          <div className="fade-cust-in">
            <div className="container-flexbox weekly-reports">
              <div className="weekly-report-calendar-container">
                <div>
                  <label><b>Pick A Week</b></label>
                  <div className="weekly-report-daypicker">
                    <DayPicker
                        selectedDays={selectedDays}
                        disabled={isFutureDate}
                        defaultMonth={moment().date(-4)}
                        numberOfMonths={2}
                        showOutsideDays
                        modifiers={modifiers}
                        onDayClick={handleDayChange}
                        onDayMouseEnter={handleDayEnter}
                        onDayMouseLeave={handleDayLeave}
                        onWeekClick={handleWeekClick}
                      />
                    </div>
                </div>
                <div  style={{ width: "40%" }}>
                  <div className="weekly-reports-toggle-container">
                    <label><b>Weekly/Monthly</b></label>
                    <div className="weekly-reports-toggle-content">
                      <div className="weekly-reports-toggle">
                        <Switch onChange={handleSwitchChange} checked={switchCheckedState} />
                      </div>
                      {switchCheckedState === false ? (<div>Weekly</div>) : (<div>Monthly</div>) }
                    </div>
                  </div>
                  <label><b>Pick A Tenant</b></label>
                  <div className="weekly-reports-select-container">
                    <Select className="weekly-reports-select-selector"
                      defaultValue={selectedTenant}
                      onChange={handleTenantChange}
                      ref={selectInputRef}
                      options={tenantList}
                    />
                    <button onClick={onTenantClear}>
                      <img src={close_img} alt="clear" />
                    </button>
                  </div>
                  <div>
                    {selectedDays !== null ? (
                        <p> Selected Week Ending: {moment(selectedDays[6]).format('LL')} </p>
                    ) : (
                        <p> No week selected </p>
                    )}
                  </div>

                  {selectedDays !== null && selectedTenant === null ? (
                    <div>
                      <p>Press Button to email me all reports, or select a specific tenant to download</p>
                    </div>
                  ) : null}

                  {selectedDays !== null && selectedTenant !== null ? (
                  <div>
                      <p>
                        Press Download Button to proceed
                      </p>
                      </div>
                  ) : null}

                  {selectedDays !== null ? (
                  <div>
                      <WeeklyReportsDownloadButton
                        weeklyRptUrl={downloadUrl}
                        tenant={selectedTenant}
                        frequency={switchCheckedState === false ? ("weekly") : ("monthly") }
                        date={moment(selectedDays[6]).format('YYYY-MM-DD')}
                        handleButtonPress={handleButtonPress}
                        handleDownloadComplete={handleDownloadComplete}
                        handleSendComplete={handleSendComplete}
                      />
                      </div>
                  ) : null}

                  {downloadButtonPressed === true ? (
                  <p>Working...</p>
                  ) : null}
                  {downloadComplete === true ? (
                  <p>Download Complete</p>
                  ) : null}
                  {sendComplete === true ? (
                  <p>Request Accepted</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyReports;
