import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";

const HorizontalDeviceAgeBarChart = ({
  apiData,
  chartTitle,
  aggregateOn
}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (apiData) {
      var maxAge = 0;
      for (var cust in apiData){
        for (var age_type in apiData[cust]){
          var age_counts = apiData[cust][age_type]['age_counts'];
          for (var age in age_counts){
            if(age > maxAge) {
              maxAge = age;
            }
          }
        }
      }

      // Not sorting for now
      const sortedCustomerNames = Object.keys(apiData);

      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      function aggregateData() {
        var aggregatedData = [];
        var age_type;
        var cname;
        if (aggregateOn === "customer") {
          for (var c in sortedCustomerNames) {
            cname = sortedCustomerNames[c];
            for (age_type in apiData[cname]) {
              let v = apiData[cname][age_type]['age_counts'][age1];
              if (v === undefined) {
                v = 0;
              }
              aggregatedData.push(v)
            }
          }
        } else {
          cname = sortedCustomerNames[0];
          for (age_type in apiData[cname]) {
            let v = apiData[cname][age_type]['age_counts'][age1];
            if (v === undefined) {
              v = 0;
            }
            aggregatedData.push(v)
          }
        }
        return aggregatedData;
      }

      var y_labels;
      if (aggregateOn === "customer") {
        y_labels = sortedCustomerNames;
      } else {
        y_labels = [
         'All Devices By RM Purchase Date',
         'Installed Devices By RM Purchase Date',
         'All Devices By Customer Purchase Date',
         'Installed Devices By Customer Purchase Date',
         ];
      }

      var datasets = [];
      for (var age1=0; age1 <= maxAge; age1++){
        var data = aggregateData();
        var label = (age1).toString() + '-' + (age1+1).toString();
        const greenCutoffAge = 2;
        const redCutoffAge = 5;
        var myGreenRatio;
        var myRedRatio;
        if (age1 >= redCutoffAge) {
          myGreenRatio = 0;
          myRedRatio = 1;
        } else {
          var x;
          if (age1 <= greenCutoffAge) {
            x = 50*age1/greenCutoffAge;
          } else {
            x = 50 + 50*(age1-greenCutoffAge)/(maxAge-greenCutoffAge);
          }
          myGreenRatio = x > 50 ? 1-2*(x-50)/100.0 : 1.0;
          myRedRatio = x > 50 ? 1.0 : 2*x/100.0;
        }
        var backgroundColor = "rgba(" + 255*myRedRatio + ", " + 255*myGreenRatio + ", 0 , 0.7)"
        var hoverBackgroundColor = "rgba(" + 255*myRedRatio + ", "+ 255*myGreenRatio + ", 0 , 1)"
        datasets.push({label: label, data: data, backgroundColor: backgroundColor, hoverBackgroundColor: hoverBackgroundColor})
      }

      const chartData = {
        labels: y_labels,
        datasets: datasets,
      };

      if (chartInstanceRef.current) {
        chartInstanceRef.current.data = chartData;
        chartInstanceRef.current.update();
      } else {
        let total = 0;
        chartInstanceRef.current = new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: {
            indexAxis: "y", // Use "y" as the index axis for horizontal chart
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                stacked: true,
                beginAtZero: true,
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              title: {
                display: true,
                text: chartTitle,
                color: "#009879",
                font: {
                  size: 16,
                },
                padding: {
                  top: 15,
                  bottom: 15,
                },
              },
              tooltip: {
                mode: "index", // Use custom tooltip mode
                intersect: true, // Disable intersect to only trigger for the hovered bar
                callbacks: {
                  beforeBody: () => {
                    total = 0;
                  },
                  label: function (context) {
                    const label = context?.dataset?.label || "";
                    const value = context?.parsed?.x;
                    total += value;
                    return `${label}: ${value}`;
                  },
                  afterBody: () => {
                    return `Total: ${total || 0}`;
                  },
                },
              },
            },
          },
        });
      }
    }
  }, [apiData, chartTitle, aggregateOn]);

  return (
    <div className="horizontal-barchart">
      <canvas ref={chartRef} />
    </div>
  );
};

export default HorizontalDeviceAgeBarChart;
