import React from "react";
import { Chart } from "react-google-charts";

const Map = ({ mapData, handleCountrySelect }) => {
  return (
    <Chart
      width={"100%"}
      height={"600px"}
      chartType="GeoChart"
      data={mapData}
      options={{
        region: "world",
        colorAxis: { colors: ["#009879","#009879"] },
        legend: "none", // Remove the color legend
      }}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 1) {
              const [selectedItem] = selection;
              const countryName = mapData[selectedItem.row + 1][0]; // Offset by 1 to skip the header row
              handleCountrySelect(countryName);
            }
          },
        },
      ]}
    />
  );
};

export default Map;
