import axios from "axios";

const my_config = require("../configure.json").API;
const backendRefreshUrl = `${my_config.token_refresh}`;
const backendGoogleUrl = `${my_config.google}`;

const authFetch = axios.create({
  //baseURL: "http://localhost:8080/",
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

authFetch.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    console.log('authFetch error')
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        if (originalConfig.url !== backendGoogleUrl && originalConfig.url !== backendRefreshUrl && !originalConfig._retry) {
          console.log('Access Token expired - will refresh');
          originalConfig._retry = true;

          try {
            const rs = await authFetch.post(backendRefreshUrl, {
              refresh: localStorage.getItem("refresh_token"),
            });
            localStorage.setItem("token", rs.data.access);

            return authFetch(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else {
          console.log('Retry of refresh for 401');
          localStorage.clear()
          window.location.href = "/login";
        }
      }
    }

    return Promise.reject(err);
  }
);

export default authFetch;