import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";

const BarChart = ({ barChartApi, exclSold }) => {
  const chartRef = useRef(null);

  // Bar Chart all customer data
  useEffect(() => {
    let chartInstance = null;

    if (barChartApi) {
      const ctx = document.getElementById("allChartData").getContext("2d");

      const labels = [];
      const currentFleetData = [];
      const installedData = [];
      const fleetCoverage = [];
      const totalDevicesData = [];
      const nonFleetDevicesData = [];
      const soldDevicesData = [];
      const lostFaultyDevicesData = [];
      const ageDeprecatedDevicesData = [];
      const spareDevices = [];
      const deviceissues = [];

      barChartApi.forEach((item) => {
        labels.push(item.customer_name);
        currentFleetData.push(item.current_fleet);
        installedData.push(item.devices_installed_on_current_fleet);
        fleetCoverage.push(item.fleet_coverage);
        totalDevicesData.push(item.total_devices_ever_sent);
        nonFleetDevicesData.push(item.devices_installed_on_non_fleet_vehicles);
        soldDevicesData.push(item.devices_on_sold_vehicles);
        lostFaultyDevicesData.push(item.unseeable_devices_lost_faulty);
        ageDeprecatedDevicesData.push(item.unseeable_devices_age_deprecated);
        spareDevices.push(item.spare_devices);
        if (exclSold === true) {
          deviceissues.push(item.device_issues_excl_sold);
        } else {
          deviceissues.push(item.device_issues);
        }
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: "Current Fleet",
            data: currentFleetData,
            backgroundColor: "rgba(0, 152, 121, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0, 152, 121, 1)",
            hidden: true,
          },
          {
            label: "Devices Installed On Current Fleet",
            data: installedData,
            backgroundColor: "rgba(75, 192, 192, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(75, 192, 192, 1)",
          },
          {
            label: "Fleet Coverage %",
            data: fleetCoverage,
            backgroundColor: "rgba(0, 63, 92, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0, 63, 92, 1)",
            hidden: true,
          },
          {
            label: "Total Devices Ever Sent",
            data: totalDevicesData,
            backgroundColor: "rgba(102, 81, 145, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(102, 81, 145, 1)",
            hidden: true,
          },
          {
            label: "Devices Installed On Non-Fleet Vehicles",
            data: nonFleetDevicesData,
            backgroundColor: "rgba(160, 81, 149, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(160, 81, 149, 1)",
            hidden: true,
          },
          {
            label: "Devices On Sold Vehicles",
            data: soldDevicesData,
            backgroundColor: "rgba(212, 80, 135, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(212, 80, 135, 1)",
            hidden: true,
          },
          {
            label: "Unusable Devices Lost/Faulty",
            data: lostFaultyDevicesData,
            backgroundColor: "rgba(232, 43, 125, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(232, 43, 125, 1)",
            hidden: true,
          },
          {
            label: "Unusable Devices Age Deprecated",
            data: ageDeprecatedDevicesData,
            backgroundColor: "rgba(249, 93, 106, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(249, 93, 106, 1)",
            hidden: true,
          },
          {
            label: "Spare Devices",
            data: spareDevices,
            backgroundColor: "rgba(255, 124, 67, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 124, 67, 1)",
            hidden: true,
          },
          {
            label: "Device Issues",
            data: deviceissues,
            backgroundColor: "rgba(255, 0, 0, 0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 0, 0, 1)",
            hidden: true,
          },
        ],
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.update();
      } else {
        chartInstance = new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                mode: "index", // Show one tooltip per dataset index when hovering over multiple bars
                intersect: false,
                callbacks: {
                  // Customize the tooltip label
                  label: function (context) {
                    const label = context.dataset.label || "";
                    const value = context.parsed.y;
                    return `${label}: ${value}`;
                  },
                },
              },
              animation: {
                duration: 1000, // Animation duration in milliseconds
                easing: "easeInOutQuad", // Easing function for the animation
                from: 0,
                to: 1,
              },
            },
          },
        });
      }
    }
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [barChartApi, exclSold]);

  return (
    <div className="bar-chart">
      <canvas ref={chartRef} id="allChartData" />
    </div>
  );
};

export default BarChart;
