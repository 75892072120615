      export const backgroundColor = [
        "rgba(148, 0, 211, 0.6)", // Indigo
        "rgba(75, 0, 130, 0.6)", // Indigo
        "rgba(0, 0, 255, 0.6)", // Blue
        "rgba(0, 70, 255, 0.6)", // Blue
        "rgba(0, 128, 0, 0.6)", // Green
        "rgba(0, 255, 0, 0.6)", // Green
        "rgba(255, 165, 0, 0.6)", // Orange
        "rgba(255, 69, 0, 0.6)", // Red-Orange
        "rgba(255, 0, 0, 0.6)", // Red
        "rgba(255, 0, 128, 0.6)", // Red
        "rgba(255, 20, 147, 0.6)", // Deep Pink
        "rgba(255, 182, 193, 0.6)", // Light Pink
        "rgba(128, 128, 128, 0.6)", // Gray
        "rgba(0, 0, 0, 0.6)", // Black
        "rgba(128, 0, 0, 0.6)", // Maroon
        "rgba(128, 0, 128, 0.6)", // Purple
        "rgba(165, 42, 42, 0.6)", // Brown
      ];

      export const hoverBackgroundColor = [
        "rgba(148, 0, 211, 1)", // Indigo
        "rgba(75, 0, 130, 1)", // Indigo
        "rgba(0, 0, 255, 1)", // Blue
        "rgba(0, 70, 255, 1)", // Blue
        "rgba(0, 128, 0, 1)", // Green
        "rgba(0, 255, 0, 1)", // Green
        "rgba(255, 165, 0, 1)", // Orange
        "rgba(255, 69, 0, 1)", // Red-Orange
        "rgba(255, 0, 0, 1)", // Red
        "rgba(255, 0, 128, 1)", // Red
        "rgba(255, 20, 147, 1)", // Deep Pink
        "rgba(255, 182, 193, 1)", // Light Pink
        "rgba(128, 128, 128, 1)", // Gray
        "rgba(0, 0, 0, 1)", // Black
        "rgba(128, 0, 0, 1)", // Maroon
        "rgba(128, 0, 128, 1)", // Purple
        "rgba(165, 42, 42, 1)", // Brown
      ]

      export const secondaryBackgroundColor = [
        "rgba(0, 63, 92, 0.8)", // Dark Blue
        "rgba(0, 152, 121, 0.8)", // #009879
        "rgba(75, 192, 192, 0.8)", // Cyan Blue
        "rgba(102, 81, 145, 0.8)",
        "rgba(160, 81, 149, 0.8)",
        "rgba(212, 80, 135, 0.8)",
        "rgba(232, 43, 125, 0.8)",
        "rgba(249, 93, 106, 0.8)",
        "rgba(255, 124, 67, 0.8)",
        "rgba(255, 140, 90, 0.8)",
        "rgba(255, 166, 0, 0.8)", // Orange
        "rgba(255, 183, 64, 0.8)", // Gold
      ];

      export const secondaryHoverBackgroundColor = [
        "rgba(0, 63, 92, 1)", // Dark Blue
        "rgba(0, 152, 121, 1)", // #009879
        "rgba(75, 192, 192, 1)", // Cyan Blue
        "rgba(102, 81, 145, 1)",
        "rgba(160, 81, 149, 1)",
        "rgba(212, 80, 135, 1)",
        "rgba(232, 43, 125, 1)",
        "rgba(249, 93, 106, 1)",
        "rgba(255, 124, 67, 1)",
        "rgba(255, 140, 90, 1)",
        "rgba(255, 166, 0, 1)", // Orange
        "rgba(255, 183, 64, 1)", // Gold
      ];

