import { useNavigate } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";

export const UsernameContext = createContext();

export const UsernameProvider = ({ children }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Check if the user is already logged in
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    setUsername("");
    localStorage.removeItem("username");
    navigate("/login");
  };

  return (
    <UsernameContext.Provider value={{ username, setUsername, handleLogout }}>
      {children}
    </UsernameContext.Provider>
  );
};
