import download from "../images/download.png";

const XLSX = require("xlsx");

const ExcelCustTableDownloadButton = ({ customerData, fileName }) => {
  const handleDownload = () => {
    if (!customerData) {
      console.error("Data is not available.");
      return;
    }

    const customerSummarySheet = XLSX.utils.json_to_sheet(customerData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      customerSummarySheet,
      "Customer Summary"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "data.xlsx"; // Use the provided fileName or fallback to "data.xlsx"
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <span id="download-btn">
      <button onClick={handleDownload} title={"Download Table Data"}>
        <img src={download} alt="download" />
      </button>
    </span>
  );
};

export default ExcelCustTableDownloadButton;
