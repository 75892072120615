import React from "react";
import toggleImage from "../images/toggle.png";
import upImage from "../images/up.png";
import logos from "../images/default_logo.png";
import "../styles/Home.css";

const TableComponent = ({
  apiData,
  sortMainColumn,
  sortMainDirection,
  handleMainSort,
  headers,
}) => {
  const customHeaderNames = {
    unseeable_devices_lost_faulty: "Unusable Devices Lost/Faulty",
    unseeable_devices_age_deprecated: "Unusable Devices Age/Deprecated",
    stock_issues_percentage: "Stock Issues %",
    device_issues_percentage: "Device Issues %",
    device_issues_excl_sold: "Device Issues",
    device_issues_excl_sold_percentage: "Device Issues %",
    devices_installed_on_current_fleet: "Installed On Fleet",
    devices_installed_on_non_fleet_vehicles: "Non-Fleet Installs",
    rental_stations_with_no_record: "Stations With No Record"
  };

  const compareValues = (valueA, valueB, isPercentage) => {
    if (isPercentage) {
      const numericValueA = parseFloat(valueA);
      const numericValueB = parseFloat(valueB);
      return numericValueA - numericValueB;
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.localeCompare(valueB);
    } else {
      const stringA = String(valueA);
      const stringB = String(valueB);
      return stringA.localeCompare(stringB);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          {apiData &&
            apiData.length > 0 &&
            headers.map((key) => {
              const customName = customHeaderNames[key];
              const formattedKey =
                customName !== undefined
                  ? customName
                  : key === "id"
                  ? "Logo"
                  : key
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ");

              const isSortingMainColumn = key === sortMainColumn;
              const arrowUp =
                isSortingMainColumn && sortMainDirection === "ascend";

              const isSelectedMainColumn = key === sortMainColumn;
              const thClassName = isSelectedMainColumn
                ? "selected"
                : "hoverable-header";

              return (
                <th
                  key={key}
                  onClick={() => handleMainSort(key)}
                  className={thClassName}
                >
                  <span className="text">{formattedKey}</span>
                  {isSelectedMainColumn && (
                    <span
                      className="icon up-icon"
                      style={{
                        transform: `rotate(${arrowUp ? 180 : 0}deg)`,
                      }}
                    >
                      <img src={upImage} alt="Up" />
                    </span>
                  )}
                  {!isSelectedMainColumn && (
                    <span className="icon toggle-icon">
                      <img src={toggleImage} alt="Toggle" />
                    </span>
                  )}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
      {apiData &&
          apiData
            .slice()
            .sort((a, b) => {
              if (sortMainColumn) {
                const valueA = a[sortMainColumn];
                const valueB = b[sortMainColumn];
                const isPercentageColumn = (sortMainColumn === "device_issues_percentage" || sortMainColumn === "device_issues_excl_sold_percentage");
                return sortMainDirection === "ascend"
                  ? compareValues(valueA, valueB, isPercentageColumn)
                  : compareValues(valueB, valueA, isPercentageColumn);
              }
              return 0;
            })
            .map((item, index) => (
              <tr key={item.id}>
                {headers.map((key, index) => {
                  if (index === 0) {
                    var logo;
                    try {
                      logo = require(`../images/${item.customer_identifier}.png`);
                    } catch (e) {
                      logo = require(`../images/default_logo.png`);
                    }
                    return (
                      <td key={`${item[key]}-${index}`}>
                        <a href={`customer/${item.customer_identifier}`}>
                          <img
                            className="img"
                            src={logo}
                            alt={item.ClientName}
                            height="40"
                            onError={(e) => (e.target.src = logos)}
                          />
                        </a>
                      </td>
                    );
                  }
                  if (key === "fleet_coverage") {
                    return (
                      <td
                        key={`${item[key]}-${index}`}
                        className="numeric-cell"
                      >
                        {`${item[key]}%`}
                      </td>
                    );
                  }

                  if (key.endsWith("_percentage")) {
                    return (
                      <td
                        key={`${item[key]}-${index}`}
                        className="numeric-cell"
                      >
                        {`${item[key]}%`}
                      </td>
                    );
                  }

                  if (typeof item[key] === "number") {
                    return (
                      <td
                        key={`${item[key]}-${index}`}
                        className="numeric-cell"
                      >
                        {item[key]}
                      </td>
                    );
                  }
                  return <td key={`${item[key]}-${index}`}>{item[key]}</td>;
                })}
              </tr>
            ))}
      </tbody>
    </table>
  );
};

export default TableComponent;
