import React from "react";

const DataTable = ({ data }) => {
  const isNumeric = (value) => /^\d+(\.\d+)?$/.test(value);

  const customHeaderNames = {
    customer_identifier: "Customer Identifier",
    fleet_coverage: "Fleet Coverage",
    current_fleet: "Current Fleet",
    additional_fleet_potential: "Additional Fleet Potential",
    devices_installed_on_current_fleet: "Devices Installed on Current Fleet",
    devices_installed_on_non_fleet_vehicles: "Devices Installed on Non-Fleet Vehicles",
    stock_issues: "Stock Issues",
    stock_issues_percentage: "Stock Issues %",
    device_issues: "Device Issues",
    device_issues_percentage: "Device Issues %",
    spare_devices: "Spare Devices",
    devices_on_sold_vehicles: "Devices on Sold Vehicles",
    unseeable_devices_lost_faulty: "Unusable Devices Lost/ Faulty",
    unseeable_devices_age_deprecated: "Unusable Devices Age/ Deprecated",
    total_devices_ever_sent: "Total Devices Ever Sent",
    daily_users: "Yesterday's Users",
    available_users: "Available Users",
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Date / Time</th>
          {Object.keys(customHeaderNames).map((key) => (
            <th key={key}>{customHeaderNames[key]}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>
              {new Date(item.created_at).toLocaleString("en-IE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </td>
            {Object.keys(customHeaderNames).map((key) => {
              if (item.hasOwnProperty(key)) {
                const value = item[key];
                const formattedValue = isNumeric(value)
                  ? parseFloat(value)
                  : value;
                  if (key === "fleet_coverage") {
                    return (
                      <td key={`${value}-${index}`} style={{ textAlign: 'right' }}>
                        {`${value}%`}
                      </td>
                    );
                  }
                  if (key.endsWith("_percentage")) {
                    return (
                      <td key={`${value}-${index}`} style={{ textAlign: 'right' }}>
                        {`${value}%`}
                      </td>
                    );
                  }
                  
                if (typeof formattedValue === "number") {
                  return (
                    <td key={`${key}-${index}`} className="numeric-cell">
                      {formattedValue}
                    </td>
                  );
                }
                return <td key={`${key}-${index}`}>{formattedValue}</td>;
              }
              return null;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
