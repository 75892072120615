import React from "react";
import "./styles/Index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

const RootComponent = () => {
  return (
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
};

createRoot(document.getElementById("root")).render(<RootComponent />);
