import email from "../images/email_white.png";
import authFetch from "../services/NetworkUtils"

const BillingEmailButton = ({excelUrl, month, year, handleButtonPress, handleSendComplete}) => {
  const handleSend = () => {
    handleButtonPress();
    const url = `${excelUrl}?month=${month}&year=${year}`;
    authFetch({ url: url,
                method: 'GET',
              })
      .then((response) => {
        handleSendComplete();
      });
  };

  return <div>
      <button className="billing-email-btn" onClick={handleSend} title={"Download Excel"}>
        <img src={email} alt="email" />
      </button>
  </div>
};

export default BillingEmailButton;
