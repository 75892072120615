import React, { useState } from 'react';
import "../styles/Home.css";
import toggleImage from "../images/toggle.png";
import upImage from "../images/up.png";

const DiDataTable = ({ apiData }) => {

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    if (!apiData || apiData.length === 0) {
        return null; // Return a meaningful fallback if there's no data
    }

    const processedData = {}; // Initialize processedData
    apiData.forEach(item => {
        const modelType = item.model_type;

        if (!processedData[modelType]) {
            processedData[modelType] = {
                modelType: modelType,
                counts: {
                    statusCodes: {},
                    issueTypes: {},
                },
            };
        }

        const counts = processedData[modelType].counts;
        const statusCode = item.status_code;
        const issueType = item.issue_type;

        counts.statusCodes[statusCode] = (counts.statusCodes[statusCode] || 0) + item.count;
        counts.issueTypes[issueType] = (counts.issueTypes[issueType] || 0) + item.count;
    });

    const compareValues = (valueA, valueB) => {
        if (typeof valueA === "number" && typeof valueB === "number") {
            return valueA - valueB;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB);
        } else {
            const stringA = String(valueA);
            const stringB = String(valueB);
            return stringA.localeCompare(stringB);
        }
    };

    const sortedModels = Object.values(processedData).sort((a, b) => {
        if (sortColumn === 'modelType') {
            return sortDirection === 'asc' ? compareValues(a.modelType, b.modelType) : compareValues(b.modelType, a.modelType);

        } else if (sortColumn === 'total') {
            const totalA = Object.values(a.counts.issueTypes).reduce((acc, count) => acc + count, 0);
            const totalB = Object.values(b.counts.issueTypes).reduce((acc, count) => acc + count, 0);
            return sortDirection === 'asc' ? compareValues(totalA, totalB) : compareValues(totalB, totalA);
        }
        return null; // to satisfy eslint, even though we should not get here
    });

    if (!apiData || apiData.length === 0) {
        return;
    }

    return (
        <table className="di-table" align="center">
            <thead className="di-table-header">
                <tr>
                <th
                        className={`hoverable ${sortColumn === 'modelType' ? 'selectedd' : ''}`}
                        onClick={() => handleSort('modelType')}
                    >
                        Model Type
                        {
                            sortColumn === 'modelType' ? (
                                <span className="icon toggle-icon">
                                    {sortDirection === 'desc' ? (
                                        <img
                                            src={upImage}
                                            alt="Up"
                                        />
                                    ) : (
                                        <img
                                            src={upImage}
                                            alt="Down"
                                            style={{
                                                transform: 'rotate(180deg)',
                                            }}
                                        />
                                    )}
                                </span>
                            ) : (
                                <span className="icon toggle-icon">
                                    <img
                                        src={toggleImage}
                                        alt="Toggle"
                                        className="toggle-icon"
                                    />
                                </span>
                            )
                        }
                    </th>
                    <th>Status Code</th>
                    <th>Problem Type</th>
                    <th>Count</th>
                    <th
                        className={`hoverable ${sortColumn === 'total' ? 'selectedd' : ''}`}
                        onClick={() => handleSort('total')}
                    >
                        Total
                        {
                            sortColumn === 'total' ? (
                                <span className="icon toggle-icon">
                                    {sortDirection === 'desc' ? (
                                        <img
                                            src={upImage}
                                            alt="Up"
                                        />
                                    ) : (
                                        <img
                                            src={upImage}
                                            alt="Down"
                                            style={{
                                                transform: 'rotate(180deg)',
                                            }}
                                        />
                                    )}
                                </span>
                            ) : (
                                <span className="icon toggle-icon">
                                    <img
                                        src={toggleImage}
                                        alt="Toggle"
                                        className="toggle-icon"
                                    />
                                </span>
                            )
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedModels.map(model => {
                    const total = Object.values(model.counts.issueTypes).reduce((acc, count) => acc + count, 0);
                    return (
                        <tr key={model.modelType}>
                            <td>{model.modelType}</td>
                            <td className="right-align">
                                {Object.entries(model.counts.statusCodes).map(([statusCode, count]) => (
                                    <div key={statusCode}>
                                        {statusCode}
                                    </div>
                                ))}
                            </td>
                            <td>
                                {Object.entries(model.counts.issueTypes).map(([issueType, count]) => (
                                    <div key={issueType}>
                                        {issueType}
                                    </div>
                                ))}
                            </td>
                            <td className="right-align">
                                {Object.entries(model.counts.issueTypes).map(([issueType, count]) => (
                                    <div key={issueType}>
                                        {count}
                                    </div>
                                ))}
                            </td>
                            <td className="right-align">
                                {total}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DiDataTable;