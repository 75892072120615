import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UsernameProvider } from "./components/UsernameContext";
import ErrorPage from "./pages/ErrorPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTopButton from './components/ScrollToTopButton';
import Customer from "./pages/Customer";
import Costs from "./pages/Costs";
import WeeklyReports from "./pages/WeeklyReports";
import Login from "./pages/Login";
import Home from "./pages/Home";

function App() {
  const location = useLocation();
  // See DJANGO settings as well
  const clientId = "834687284862-a0s88hr6jlkad5o40rcml19m7ca4ufa6.apps.googleusercontent.com";

  const isRoutePage =
    location.pathname === "/" ||
    location.pathname.startsWith("/customer/") ||
    location.pathname.startsWith("/costs") ||
    location.pathname.startsWith("/weekly_reports");

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <UsernameProvider>
      <ScrollToTopButton />
        {isRoutePage && <Navbar />}

        <Routes>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="customer/:param1" element={<Customer />} />
          <Route path="costs" element={<Costs />} />
          <Route path="weekly_reports" element={<WeeklyReports />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        {isRoutePage && <Footer />}
      </UsernameProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
