// Customer.js
import React, { useState } from "react";
import MonthPicker from "simple-react-month-picker";
import moment from "moment";
import "../styles/Home.css";
import "../styles/Costs.css";
import CostsDownloadButton from "../components/CostsDownloadButton";
import BillingEmailButton from "../components/BillingEmailButton";

const Costs = () => {
  const config = require("../configure.json").API;
  const [gcpSelected, setGcpSelected] = useState(null);
  const [gcpDownloadButtonPressed, setGcpDownloadButtonPressed] = useState(null);
  const [gcpDownloadComplete, setGcpDownloadComplete] = useState(null);
  const [billingSelected, setBillingSelected] = useState(null);
  const [billingSendButtonPressed, setBillingSendButtonPressed] = useState(null);
  const [billingSendComplete, setBillingSendComplete] = useState(null);
  const [monthYear, setMonthYear] = useState({month: '', year: 0});
  const gcp_presets = [
    {
      title: "Last month",
      start: moment().subtract(1, "month").startOf("month").toDate(),
      end: moment().subtract(1, "month").startOf("month").toDate()
    },
    {
      title: "Last 3 months",
      start: moment().subtract(3, "month").startOf("month").toDate(),
      end: moment().subtract(1, "month").startOf("month").toDate(),
    },
    {
      title: "Last 6 months",
      start: moment().subtract(6, "month").startOf("month").toDate(),
      end: moment().subtract(1, "month").startOf("month").toDate(),
    },
    {
      title: "Year To Date",
      start: moment().startOf("year").toDate(),
      end: moment().subtract(1, "month").startOf("month").toDate(),
    }
  ];

  const billing_presets = [
    {
      title: "Last month",
      offset: 1
    },
    {
      title: "2 months ago",
      offset: 2
    },
    {
      title: "3 months ago",
      offset: 3
    },
    {
      title: "4 months ago",
      offset: 4
    }
 ];

  const costsExcelUrl = `${config.api_costs_excel}`;
  const billingExcelUrl = `${config.api_billing_excel}`;

  const handleGcpButtonPress = () => {
    setGcpDownloadButtonPressed(true);
  };

  const handleGcpDownloadComplete = () => {
    setGcpDownloadComplete(true);
  };

  const onGcpMonthChange = (d) => {
    setGcpSelected(d);
    setGcpDownloadButtonPressed(false);
    setGcpDownloadComplete(false);
  }

  const handleBillingButtonPress = () => {
    setBillingSendButtonPressed(true);
  };

  const handleBillingSendComplete = () => {
    setBillingSendComplete(true);
  };

  const onBillingMonthChange = (offset) => {
    const date = moment().subtract(offset, "month").startOf("month")
    setMonthYear({ month: date.month()+1, year: date.year() })
    setBillingSelected(date);
    setBillingSendButtonPressed(false);
    setBillingSendComplete(false);
  };

  const getMonthValue = () => {
    const month = monthYear && monthYear.month ? monthYear.month : 0;
    const year = monthYear && monthYear.year ? monthYear.year : 0;
    return month && year ? `${month < 10 ? '0' + month : month}-${year}` : "Select Month";
  };

  return (
    <>
      <div className="fade-in">
        <div style={{ overflowX: "auto" }}>
          <div className="fade-cust-in">
            <div className="container-flexbox cost-picker">
              <div className="weekly-report-calendar-container">
                <div>
                  <label><b>GCP Cost Report</b></label>
                  <div>This month's data will be available from 3rd day of next month. No data prior to Jan 2022</div>
                  <MonthPicker
                    presets={gcp_presets}
                    highlightCol="#24b364"
                    closeDelay={500}
                    onChange={(d) => onGcpMonthChange(d)}
                  />
                  {gcpSelected !== null ? (
                    <div>
                      <p>
                        Selected Range: {moment(gcpSelected[0]).format("D MMM YYYY")} to {moment(gcpSelected[1]).format("D MMM YYYY")}
                      </p>
                      <p>
                        Press Download Button to proceed
                      </p>
                      <CostsDownloadButton
                        excelUrl={costsExcelUrl}
                        range={gcpSelected}
                        handleButtonPress={handleGcpButtonPress}
                        handleDownloadComplete={handleGcpDownloadComplete}
                      />
                    </div>
                  ) : null}
                  {gcpDownloadButtonPressed === true ? (
                  <p>Downloading...</p>
                  ) : null}
                  {gcpDownloadComplete === true ? (
                  <p>Download Complete</p>
                  ) : null}
                </div>

                <div style={{ width: "50%", paddingLeft: "20px" }}>
                  <label><b>Billing Files</b></label>
                  <div>
                    <select className="billing-picker" onChange={(e) => onBillingMonthChange(e.target.value)}>
                      <option value="0" disabled selected>Select Month</option>
                      {billing_presets.map((preset, index) => (
                        <option key={index} value={preset.offset}>{preset.title}</option>
                      ))}
                    </select>
                    {billingSelected !== null ? (
                    <div>
                      <p>
                        Selected Month: {getMonthValue()}
                      </p>
                      <p>
                        Press Button to mail file. This may take a few minutes.
                      </p>
                      <BillingEmailButton
                        excelUrl={billingExcelUrl}
                        month={monthYear.month}
                        year={monthYear.year}
                        handleButtonPress={handleBillingButtonPress}
                        handleSendComplete={handleBillingSendComplete}
                      />
                    </div>
                  ) : null}
                  {billingSendButtonPressed === true ? (
                  <p>Requesting...</p>
                  ) : null}
                  {billingSendComplete === true ? (
                  <p>Request Lodged</p>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Costs;
