import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";

const CustLineChart = ({ custLineChartApi, chartLabels, chartData, inclSold }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); // Keep track of the chart instance

  useEffect(() => {
    let chartInstance = chartInstanceRef.current; // Get the current chart instance

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const additionalFleetPotential = [];
      const currentFleetData = [];
      const installedData = [];
      const totalDevicesData = [];
      const nonFleetDevicesData = [];
      const soldDevicesData = [];
      const lostFaultyDevicesData = [];
      const ageDeprecatedDevicesData = [];
      const spareDevices = [];
      const deviceIssues = [];
      const deviceIssuesExclSold = [];
      const fleetCoverage = [];

      custLineChartApi.forEach((item) => {
        const record = item.record;
        additionalFleetPotential.push(record.additional_fleet_potential);
        currentFleetData.push(record.current_fleet);
        installedData.push(record.devices_installed_on_current_fleet);
        totalDevicesData.push(record.total_devices_ever_sent);
        nonFleetDevicesData.push(
          record.devices_installed_on_non_fleet_vehicles
        );
        fleetCoverage.push(record.fleet_coverage);
        soldDevicesData.push(record.devices_on_sold_vehicles);
        lostFaultyDevicesData.push(record.unseeable_devices_lost_faulty);
        ageDeprecatedDevicesData.push(record.unseeable_devices_age_deprecated);
        spareDevices.push(record.spare_devices);
        deviceIssuesExclSold.push(record.device_issues_excl_sold);
        deviceIssues.push(record.device_issues);
      });

      const chartData = {
        labels: chartLabels,
        datasets: [
          {
            label: "Additional Fleet Potential",
            data: additionalFleetPotential,
            fill: true,
            backgroundColor: "rgba(0, 63, 92,0.1)",
            borderColor: "rgba(0, 63, 92, 1)",
            hoverBackgroundColor: "rgba(0, 63, 92, 1)",
            pointBackgroundColor: Array(additionalFleetPotential.length).fill(
              "rgba(0, 63, 92, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Current Fleet",
            data: currentFleetData,
            fill: true,
            backgroundColor: "rgba(0, 152, 121,0.1)",
            borderColor: "rgba(0, 152, 121, 1)",
            hoverBackgroundColor: "rgba(0, 152, 121, 1)",
            pointBackgroundColor: Array(currentFleetData.length).fill(
              "rgba(0, 152, 121, 1)"
            ),
            yAxisID: "y",
          },
          {
            label: "Devices Installed On Current Fleet",
            data: installedData,
            fill: true,
            backgroundColor: "rgba(75, 192, 192,0.1)",
            borderColor: "rgba(75, 192, 192, 1)",
            hoverBackgroundColor: "rgba(75, 192, 192, 1)",
            pointBackgroundColor: Array(installedData.length).fill(
              "rgba(75, 192, 192, 1)"
            ),
            yAxisID: "y",
          },

          {
            label: "Fleet Coverage %",
            data: fleetCoverage,
            fill: true,
            backgroundColor: "rgba(87, 135, 167, 0.1)",
            borderColor: "rgba(87, 135, 167, 1)",
            hoverBackgroundColor: "rgba(87, 135, 167, 1)",
            pointBackgroundColor: Array(fleetCoverage.length).fill(
              "rgba(87, 135, 167, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Total Devices Ever Sent",
            data: totalDevicesData,
            fill: true,
            backgroundColor: "rgba(102, 81, 145,0.1)",
            borderColor: "rgba(102, 81, 145, 1)",
            hoverBackgroundColor: "rgba(102, 81, 145, 1)",
            pointBackgroundColor: Array(totalDevicesData.length).fill(
              "rgba(102, 81, 145, 1)"
            ),
            hidden: true,
            yAxisID: "y",
          },
          {
            label: "Devices Installed On Non-Fleet Vehicles",
            data: nonFleetDevicesData,
            fill: true,
            backgroundColor: "rgba(160, 81, 149,0.1)",
            borderColor: "rgba(160, 81, 149, 1)",
            hoverBackgroundColor: "rgba(160, 81, 149, 1)",
            pointBackgroundColor: Array(nonFleetDevicesData.length).fill(
              "rgba(160, 81, 149, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Devices On Sold Vehicles",
            data: soldDevicesData,
            fill: true,
            backgroundColor: "rgba(212, 80, 135,0.1)",
            borderColor: "rgba(212, 80, 135, 1)",
            hoverBackgroundColor: "rgba(212, 80, 135, 1)",
            pointBackgroundColor: Array(soldDevicesData.length).fill(
              "rgba(212, 80, 135, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Unusable Devices Lost/Faulty",
            data: lostFaultyDevicesData,
            fill: true,
            backgroundColor: "rgba(232, 43, 125,0.1)",
            borderColor: "rgba(232, 43, 125, 1)",
            hoverBackgroundColor: "rgba(232, 43, 125, 1)",
            pointBackgroundColor: Array(lostFaultyDevicesData.length).fill(
              "rgba(232, 43, 125, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Unusable Devices Age Deprecated",
            data: ageDeprecatedDevicesData,
            fill: true,
            backgroundColor: "rgba(249, 93, 106,0.1)",
            borderColor: "rgba(249, 93, 106, 1)",
            hoverBackgroundColor: "rgba(249, 93, 106, 1)",
            pointBackgroundColor: Array(ageDeprecatedDevicesData.length).fill(
              "rgba(249, 93, 106, 1)"
            ),
            hidden: true,
            yAxisID: "y1",
          },
          {
            label: "Spare Devices",
            data: spareDevices,
            fill: true,
            backgroundColor: "rgba(255, 124, 67,0.1)",
            borderColor: "rgba(255, 124, 67, 1)",
            hoverBackgroundColor: "rgba(255, 124, 67, 1)",
            pointBackgroundColor: Array(spareDevices.length).fill(
              "rgba(255, 124, 67, 1)"
            ),
            hidden: true,
            yAxisID: "y",
          },
          {
            label: "Issues Incl Sold",
            data: deviceIssues,
            fill: true,
            backgroundColor: "rgba(245, 135, 66, 0.1)",
            borderColor: "rgba(245, 135, 66, 1)",
            hoverBackgroundColor: "rgba(245, 135, 66, 1)",
            pointBackgroundColor: Array(deviceIssues.length).fill(
              "rgba(245, 135, 66, 1)"
            ),
            yAxisID: "y1",
            hidden: !inclSold,
          },
          {
            label: "Device Issues",
            data: deviceIssuesExclSold,
            fill: true,
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            borderColor: "rgba(255, 0, 0, 1)",
            hoverBackgroundColor: "rgba(255, 0, 0, 1)",
            pointBackgroundColor: Array(deviceIssuesExclSold.length).fill(
              "rgba(255, 0, 0, 1)"
            ),
            yAxisID: "y1",
            hidden: inclSold,
          },
        ],
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.update();
      } else {
        chartInstance = new Chart(ctx, {
          type: "line",
          data: chartData,
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              tooltip: {
                mode: "index",
                intersect: false,
              },
            },
            hover: {
              mode: "index",
              intersect: false,
            },

            scales: {
              y: {
                type: "linear",
                display: "auto",
                position: "left",
              },
              y1: {
                type: "linear",
                display: "auto",
                position: "right",

                // grid line settings
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
            },
          },
        });
        chartInstanceRef.current = chartInstance;
      }
    }
  }, [custLineChartApi, chartLabels, chartData, inclSold]);

  return (
    <div className="cust-linechart" style={{ padding: "15px" }}>
      <canvas ref={chartRef} id="custDevices" />
    </div>
  );
};

export default CustLineChart;
