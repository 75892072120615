import download from "../images/download.png";
const XLSX = require("xlsx");

const ExcelHistoricStatsDownloadButton = ({ historicStats, types, inclSold, sheetName }) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
  var file_type;
  if (types === "actions_issues") {
    file_type = "ActionHistory"
  } else if (types === "rental_api_metrics") {
    file_type = "RentalApiHistory"
  } else if (types === "feature_usage") {
    file_type = "FeatureUsage"
  } else {
    file_type = "DeviceIssueHistory"
  }
  const fileName = `${file_type}_${formattedDate}.xlsx`;

  function mapObjectCounts(dayData) {
      var newData = {};
      for (const key in dayData) {
          if (key === "customer_identifier" || key === "timestamp") {
              newData[key] = dayData[key];
              continue;
          } else {
              if (file_type === "DeviceIssueHistory") {
                  if (inclSold) {
                      newData[key] = dayData[key]["count"];
                  } else {
                      newData[key] = dayData[key]["count_excl_sold"];
                  }
              } else {
                  newData[key] = dayData[key];
              }
          }
      }
      return newData;
  }

  const handleDownload = () => {
    if (!historicStats) {
      console.error("Data is not available.");
      return;
    }

    const counts = historicStats.map((dayData) => mapObjectCounts(dayData));

    const historicStatsSheet = XLSX.utils.json_to_sheet(counts);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      historicStatsSheet,
      sheetName
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "data.xlsx"; // Use the provided fileName or fallback to "data.xlsx"
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <span id="download-btn">
      <button onClick={handleDownload} title={"Download Data"}>
        <img src={download} alt="download" />
      </button>
    </span>
  );
};

export default ExcelHistoricStatsDownloadButton;
