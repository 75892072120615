import React, { useState } from "react";
import download from "../images/download.png";
import loading from "../images/loading.gif";
import authFetch from "../services/NetworkUtils"

const XLSX = require("xlsx");
const config = require("../configure.json").API;

const ExcelStationIssuesDownloadButton = ({ fileName }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);
    Promise.all([
      authFetch(`${config.api_rental_station_metrics}`).then((response) => response.data)
    ])
    .then(
    ([
      api_rental_station_metrics,
    ]) => {
      const customerSummarySheet = XLSX.utils.json_to_sheet(api_rental_station_metrics);
      customerSummarySheet["!cols"] = [];
      customerSummarySheet["!cols"][0] = {wch: 15};
      customerSummarySheet["!cols"][1] = {wch: 20};
      customerSummarySheet["!cols"][2] = {wch: 40};
      for (const x of Array(12).keys()) {
        customerSummarySheet["!cols"][3+x] = {wch: 10}
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        customerSummarySheet,
        "Customer Summary"
      );

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "data.xlsx"; // Use the provided fileName or fallback to "data.xlsx"
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsDownloading(false);
    })
  };

  return (
    <span id="big-down-btn">

     <div>
       <button
        onClick={handleDownload}
        disabled={isDownloading}
        title={"Download Station Issues"}>

        <img src={isDownloading === false ? (download):(loading)} alt="download" />
        </button>
      </div>
    </span>
  );
};

export default ExcelStationIssuesDownloadButton;
