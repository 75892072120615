import React from "react";
import "../styles/Error.css";

const ErrorPage = () => {
  return (
    <div className="error">
      <h1>404</h1>
      <p>We couldn't find what you were looking for.</p>
      <div className="error-link">
        <a href="/">Back to home</a>
      </div>
    </div>
  );
};

export default ErrorPage;
