import download_white from "../images/download_white.png";
import email_white from "../images/email_white.png";
import authFetch from "../services/NetworkUtils"

const WeeklyReportsDownloadButton = ({weeklyRptUrl, frequency, tenant, date, handleButtonPress, handleDownloadComplete, handleSendComplete}) => {

  const handleDownload = () => {
    handleButtonPress();
    authFetch({ url: `${weeklyRptUrl}?frequency=${frequency}&date=${date}&tenant=${tenant}`,
                method: 'GET',
                responseType: 'blob', // important
              })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const filename = `${tenant}_${frequency}_we${date}.pdf`
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        handleDownloadComplete();
      });
  };

    const handleSend = () => {
    handleButtonPress();
    authFetch({ url: `${weeklyRptUrl}?frequency=${frequency}&date=${date}`,
                method: 'GET',
              })
      .then((response) => {
        handleSendComplete();
      });
  };

  return <div className="weekly-down-btn">
      {tenant !== null ? (
      <button onClick={handleDownload} title={"Download report"}>
        <img src={download_white} alt="download" />
      </button>
      ) : (
      <button className="weekly-down-btn" onClick={handleSend} title={"Email all reports"}>
        <img src={email_white} alt="email" />
      </button>
      )}
  </div>
};

export default WeeklyReportsDownloadButton;
