import React, { useState, useEffect } from "react";
import "../styles/Index.css";
import scroll from "../images/scroll.png";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 10) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };

  return (
    <img
      className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
      src={scroll}
      alt="Scroll"
      onClick={scrollToTop}
    />
  );
};

export default ScrollToTopButton;
