import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";
import ColourUtils from "../services/ColourUtils"

const DoughnutChart = ({ pieChartApi, chartTitle, aggregateOn, inclSold, showTrial }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;

    const WRAPPER_COLORS = [
      'rgb(255, 255, 0)',
      'rgb(184, 184, 184)',
      'rgb(255, 0, 0)',
    ];

    if (pieChartApi) {
      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      const processedData = {};
      const wrapperProcessedData = {};
      pieChartApi.forEach((item) => {
        if (!showTrial && (item.customer_identifier === "TRIAL")) {
          return;
        }
        var key;
        if (aggregateOn === "issues") {
          key = item.issue_type;
        } else {
          key = item.action;
        }

        if (!processedData[key]) {
          processedData[key] = {
            key: key,
            count: 0,
          };
        }
        if (inclSold === false) {
          processedData[key].count += item.count_excl_sold;
        } else {
          processedData[key].count += item.count;
        }

        if (aggregateOn === "actions") {
          key = key.split(' -')[0];
          if (!wrapperProcessedData[key]) {
            wrapperProcessedData[key] = {
              key: key,
              count: 0,
            };
          }
          if (inclSold === false) {
            wrapperProcessedData[key].count += item.count_excl_sold;
          } else {
            wrapperProcessedData[key].count += item.count;
          }
        }

      });

      var uniqueKeys;
      var chartType;
      var datasets = [];
      var wrapperDataset = null;
      var labels = null;
      var wrapperKeys = null;

      const backgroundColors = [];
      const hoverBackgroundColors = [];
      if (aggregateOn === "issues") {
        chartType = "doughnut";
        uniqueKeys = Array.from(
          new Set(pieChartApi.filter((item) => { return !item.issue_type.startsWith("OK:")}).map((item) => item.issue_type))
        );
        labels = uniqueKeys;
        for (let index = 0; index < labels.length; index++) {
          backgroundColors.push(ColourUtils.getAnomalyBgColour(labels[index % labels.length]));
          hoverBackgroundColors.push(ColourUtils.getAnomalyHoverColour(labels[index % labels.length]));
        };
      } else {
        // No longer used
        chartType = "doughnut";
        uniqueKeys = Array.from(
          new Set(pieChartApi.map((item) => item.action))
        );
        wrapperKeys = Object.keys(wrapperProcessedData);
        const wrapperData = wrapperKeys.map((key) => {
          const count = wrapperProcessedData[key]?.count || 0;
          return count;
        });
        for (let index = 0; index < wrapperKeys.length; index++) {
          backgroundColors.push(WRAPPER_COLORS[index % WRAPPER_COLORS.length]);
        };
        wrapperDataset = {
            data: wrapperData,
            backgroundColor: backgroundColors,
        };
        labels = wrapperKeys;
      }

      var issuedata = uniqueKeys.map((key) => {
        const count = processedData[key]?.count || 0;
        return count;
      });

      datasets.push({
            data: issuedata,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
      });

      var legendPlug = {
        display: true,
        position: "bottom",
        align: "start", // Align the legend to the start (left) side
      };

      var tooltip = { mode: 'nearest', position: 'nearest' };

      if (aggregateOn === "actions") {
        datasets.push(wrapperDataset);
        tooltip = {
          callbacks: {
            label: function(context) {
              var lab;
              if (context.datasetIndex === 0) {
               // Outer ring
               lab = uniqueKeys[context.dataIndex] + ': ' + context.formattedValue;
              } else if (context.datasetIndex === 1) {
                // Inner ring
                lab = wrapperKeys[context.dataIndex] + ': ' + context.formattedValue;
              }
              return lab;
            },
            title: () => null,
          }
        };
        legendPlug = {
          display: true,
          position: "bottom",
          align: "start", // Align the legend to the start (left) side
          labels: {
            generateLabels: function(chart) {
              const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
              const labelsOriginal = original.call(this, chart);
              labelsOriginal.forEach(label => {
                label.fillStyle = WRAPPER_COLORS[label.index % WRAPPER_COLORS.length];
              });
              return labelsOriginal;
            }
          }
        }
      }

      const chartData = {
        labels: labels,
        datasets: datasets,
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.update();
      } else {
        chartInstance = new Chart(ctx, {
          type: chartType,
          data: chartData,
          options: {
            maintainAspectRatio: true,
            responsive: true,
            plugins: {
              legend: legendPlug,
              tooltip: tooltip,
              title: {
                display: true,
                text: chartTitle, // Title text passed as prop
                color: "#a7a6a6",
                font: {
                  size: 20,
                },
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [pieChartApi, chartTitle, aggregateOn, inclSold, showTrial]);

  return (
    <>
      {/* Pie Chart total device installs */}
      <div className="chart-container">
        <canvas ref={chartRef} id="totalFleet" />
      </div>
    </>
  );
};

export default DoughnutChart;
