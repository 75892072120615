import download from "../images/download_white.png";
import authFetch from "../services/NetworkUtils"
import moment from "moment";

const CostsDownloadButton = ({excelUrl, range, handleButtonPress, handleDownloadComplete}) => {
  const last_month = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DDT00:00:00");
  if (range == null) {
    range = Array(2).fill(last_month)
  }
  const startYear = moment(range[0]).format("YYYY");
  const startMonth = moment(range[0]).format("M");
  var endYear = moment(range[1]).format("YYYY");
  var endMonth = moment(range[1]).format("M");
  if ((endYear === moment().format("YYYY")) && (endMonth === moment().format("M"))) {
    endYear = moment(last_month).format("YYYY");
    endMonth = moment(last_month).format("M");
  }

  const handleDownload = () => {
    handleButtonPress();
    const url = `${excelUrl}?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}`;
    authFetch({ url: url,
                method: 'GET',
                responseType: 'blob', // important
              })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
        const filename = "Costs " + formattedDate + ".xlsx"
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        handleDownloadComplete();
      });
  };

  return <div className="costs-download-btn">
      <button onClick={handleDownload} title={"Download Excel"}>
        <img src={download} alt="download" />
      </button>
  </div>
};

export default CostsDownloadButton;
