
const anomalytocolourMap = [
  {anomaly: "Unauth Disconnect", rgb: "148, 0, 211"}, // Indigo
  {anomaly: "Never Received Data", rgb: "75, 0, 130"}, // Indigo
  {anomaly: "Stuck In Position", rgb: "0, 0, 255"}, // Blue
  {anomaly: "No Mileage", rgb: "0, 70, 255"}, // Blue
  {anomaly: "OK:Bad GPS Fix", rgb: "0, 128, 0"}, // Green
  {anomaly: "OK:No GPS Signal", rgb: "0, 255, 0"}, // Green
  {anomaly: "No Data", rgb: "255, 165, 0"}, // Orange
  {anomaly: "Other", rgb: "255, 69, 0"}, // Red-Orange
  {anomaly: "Multiple Impacts", rgb: "255, 0, 0"}, // Red
  {anomaly: "Pre Active", rgb: "255, 0, 128"}, // Red
  {anomaly: "Battery Issue", rgb: "255, 20, 147"}, // Deep Pink
  {anomaly: "Multiple Disconnects", rgb: "255, 182, 193"}, // Light Pink
  {anomaly: "OK:Open journey > 6hrs", rgb: "128, 128, 128"}, // Gray
  {anomaly: "Open journey > 24hrs", rgb: "0, 0, 0"}, // Black
  {anomaly: "No Journeys", rgb: "128, 0, 0"}, // Maroon
  {anomaly: "Open journey > 1wk", rgb: "128, 0, 128"}, // Purple
  {anomaly: "No Data Since Installation", rgb: "128, 0, 62"}, // Maroon
  {anomaly: "Unimplemented State", rgb: "165, 42, 42"}, // Brown
];

const severitytocolourMap = [
  {severity: "INFO (2XX)", rgb: "0, 128, 0"}, // Green
  {severity: "WARNING (3XX)", rgb: "255, 255, 0"}, // Yellow
  {severity: "ALERT (4XX)", rgb: "255, 165, 0"}, // Orange
  {severity: "CRITICAL (5XX)", rgb: "255, 0, 0"}, // Red
];

const ColourUtils = function () {};

ColourUtils.getAnomalyBgColour = function (anomaly) {
   const rgb_map = anomalytocolourMap.find(x => x.anomaly === anomaly);
   if (rgb_map) {
    return "rgba(" + rgb_map.rgb + ", 0.6)";
   }
   return "rgba(0,0,0, 0.6)";
};

ColourUtils.getAnomalyHoverColour = function (anomaly) {
   const rgb_map = anomalytocolourMap.find(x => x.anomaly === anomaly);
   if (rgb_map) {
    return "rgba(" + rgb_map.rgb + ", 0.6)";
   }
   return "rgba(0,0,0, 0.6)";
};

ColourUtils.getSeverityBgColour = function (severity) {
   const rgb_map = severitytocolourMap.find(x => x.severity === severity);
   if (rgb_map) {
    return "rgba(" + rgb_map.rgb + ", 0.6)";
   }
   return "rgba(0,0,0, 0.6)";
};

ColourUtils.getSeverityHoverColour = function (severity) {
   const rgb_map = severitytocolourMap.find(x => x.severity === severity);
   if (rgb_map) {
    return "rgba(" + rgb_map.rgb + ", 0.6)";
   }
   return "rgba(0,0,0, 0.6)";
};

export default ColourUtils;