import React from "react";
import footlogo from "../images/logo.png";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer id="foot">
      <div>
        <img
          className="img"
          src={footlogo}
          alt="Rentalmatics logo"
          height="45"
        />
      </div>
    </footer>
  );
};

export default Footer;
